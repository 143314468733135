define('jsonParser',["jquery"], function ($) {
    return function (json) {
        var newJson;
        if (typeof newJson == 'object') {
            newJson = json;
        } else {
            newJson = JSON.parse(json);
        }

        return newJson;
    }
});

