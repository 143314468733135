define('pages/select-questions',['jquery', 'vue', 'GA', 'analyticsSender'], function ($, Vue, GA, analyticsSender) {
    if ($('#suggested-questions-list').length) {
        var addElement = '<i class="fa add fa-plus-circle"></i>   ADD BACK';
        var removeElement = '<i class="fa remove fa-times-circle"></i>    REMOVE';
        var vm = new Vue({
            el: '#vue-container',
            data: {
                loadMoreCounter: 0,
                successMessages: {
                    'removing...': 'removed',
                    'adding back...': 'added back'
                },
                failureMessages: {
                    'removing...': 'failed to remove',
                    'adding back...': 'failed to add back'
                },
                buttonTransition: {
                    'false': addElement,
                    'true': removeElement
                },
                message: 'Loading Content...',
                questions: [],
                viewed: '',
                total: $('#available-question-count').html(),
                remaining: null,
                initialized: 'false',
                custom_questions: {
                    form_error: false,
                    questions: []
                }
            },
            ready: function () {
                this.init();
            },
            watch: {
                'questions': {
                    deep: true,
                    handler: function (val) {
                        var questions = '';
                        for (var id in val) {
                            questions += vm.questions[id].id + ',';
                        }
                        this.updateViewed(questions);
                        this.updateCounts();
                    }
                }
            },
            methods: {
                init: function () {
                    this.loadQuestions('initialize');
                    this.getCustomQuestions();
                },
                updateCounts: function () {
                    this.viewed = this.questions.length;
                    this.remaining = this.total - this.viewed
                },
                moreQuestions: function () {
                    this.loadQuestions('false');
                },
                loadQuestions: function (initialize) {
                    $.ajax({
                        url: '/load-more-questions',
                        type: 'GET',
                        data: {initialize: initialize, worksheet: $('#worksheet_id').html()},
                        contentType: 'application/json',
                        dataType: 'json',
                        success: function (response) {

                            if (response.questions) {
                                vm.message = '';
                                vm.addToQuestions(response.questions)
                            } else {
                                vm.message = response.result;
                                $('#load-more-button').hide()
                            }
                            vm.loadMoreCounter ++;
                        },
                        error: function (response) {
                            vm.message = response.result;

                        }
                    });
                },
                updateViewed: function (questions) {
                    $.ajax({
                        url: '/decision-worksheet/update-viewed/',
                        data: {
                            questions: questions,
                            worksheet: $('#worksheet_id').html()
                        },
                        type: 'GET',
                        contentType: 'application/json',
                        dataType: 'json',
                        success: function (response) {

                        },
                        error: function (response) {
                        }
                    });
                },
                toggleSingleSelection: function (question) {
                    var wasChecked;
                    if (question.checked == true) {
                        wasChecked = true;
                        question.message = 'removing...';
                    } else {
                        wasChecked = false;
                        question.message = 'adding back...';
                    }
                    $.ajax({
                        url: '/decision-worksheet/toggle-single-question/',
                        data: {
                            question: question,
                            worksheet: $('#worksheet_id').html()
                        },
                        type: 'GET',
                        contentType: 'application/json',
                        dataType: 'json',
                        success: function (response) {
                            analyticsSender.send(question.message.replace('...', ' Question'), 'click', response.question.content);
                            question.buttonText = vm.buttonTransition[question.checked];
                            question.message = vm.successMessages[question.message];
                        },
                        error: function (response) {
                            question.checked = wasChecked;
                            question.buttonText = vm.buttonTransition[wasChecked];
                            question.message = vm.failureMessages[question.message];
                        },
                        complete: function () {
                            vm.resetQuestionMessage(question);
                        }
                    });
                },
                addToQuestions: function (questions) {
                    $.each(questions, function (key, question) {
                        if (vm.questions.indexOf(key) == -1) {
                            var isChecked = false;
                            var buttonText = addElement;
                            if (question.pivot.checked == 1) {
                                isChecked = true;
                                buttonText = removeElement;
                            }
                            vm.questions.push({
                                id: key,
                                content: question.content,
                                checked: isChecked,
                                message: '',
                                buttonText: buttonText
                            });
                        }
                    });
                },
                resetQuestionMessage: function (question) {
                    setTimeout(function () {
                        question.message = '';
                    }, 1000);
                },
                setCSRFHeader: function() {
                    var token = $('input[name="_token"]').val();
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': token
                        }
                    });
                },
                addCustom: function() {
                    var that = this,
                        $input = $('input#add-own-input'),
                        question = $input.val();

                    if (question == '') {
                        $input.addClass('error');
                        return false;
                    }
                    $input.removeClass('error');

                    that.setCSRFHeader();
                    // post the custom question
                    $.ajax({
                        type: "POST",
                         url: "/add-my-own-question/add",
                         data: {
                            "question": question,
                            "worksheet_id": that.getWorkSheetId()
                         },
                         success: function (data) {
                            // get the data and use it to fill our custom question
                            // console.log(data);
                            if (data !== '') {
                                // refresh custom questions
                                that.getCustomQuestions();
                                that.custom_questions.form_error = false;
                            }
                         },
                         error: function (data) {
                            console.log("Something went wrong");
                            that.custom_questions.form_error = true;
                         }
                    });
                },
                getWorkSheetId: function() {
                    return $('#worksheet_id').html();
                },
                getCustomQuestions: function() {
                    var that = this;

                    $.ajax({
                        type: "GET",
                        url: "/add-my-own-question/get/" + that.getWorkSheetId(),
                        success: function (data) {
                            // console.log(data);
                            if (data) {
                                var questions = data.map(function (question) {
                                    if (question.checked == 0) {
                                        question.checked = false;
                                    }
                                    else {
                                        question.checked = true;
                                    }
                                    question.message = '';
                                    question.buttonText = vm.buttonTransition[question.checked.toString()];
                                    return question;
                                });

                                that.custom_questions.questions = questions;
                            }
                        }
                    });
                },
                toggleSingleCustom: function(question) {
                    var that = this;

                    if (question.checked == true) {
                        wasChecked = true;
                        question.message = 'removing...';
                    } else {
                        wasChecked = false;
                        question.message = 'adding back...';
                    }

                    that.setCSRFHeader();
                    $.ajax({
                        type: 'POST',
                        url: '/add-my-own-question/toggle',
                        data: {
                            id: question.id,
                            worksheet_id: question.decision_worksheet_id
                        },
                        success: function(data) {
                            that.getCustomQuestions();
                        },
                        error: function(data) {
                            alert("Something went wrong. Could not delete");
                        }
                    });
                },
                deleteSingleCustom: function(question) {
                    var that = this;

                    if (confirm('Are you sure you want to delete question?') ){
                        that.setCSRFHeader();
                        $.ajax({
                            type: 'POST',
                            url: '/add-my-own-question/delete',
                            data: {
                                id: question.id,
                                worksheet_id: question.decision_worksheet_id
                            },
                            success: function(data) {
                                that.getCustomQuestions();
                            },
                            error: function(data) {
                                alert("Something went wrong. Could not delete");
                            }
                        });
                    } else {
                        return false;
                    }

                }
            }
        });
        analyticsSender.loadPage('select-questions');
    }
});

