define('pages/success',['jquery', 'jsonParser', 'GA', 'analyticsSender'], function ($, jsonParser, GA, analyticsSender) {
    $(document).ready(function () {
        if ($('#success-page').length) {
            var gaData = $('#ga-section').text();
            var parsed = jsonParser(gaData);
            $('#ga-question-counter').data('data-value', parsed.questionCount);
            analyticsSender.loadPage('success');
            analyticsSender.send('Selected Questions', 'count', null, parsed.questionCount);
            $.each(parsed.concerns, function (key, concern) {
                analyticsSender.send('Questions', 'What are you worried about?', concern);
            });
            $.each(parsed.interactions, function (key, interaction) {
                analyticsSender.send('Questions', 'Why are you seeing the doctor now?', interaction)
            });
        }
    });
});
