define('utils/retina',["vendor/retina"], function () {
        if (Retina.isRetina()) {
            var images = document.getElementsByTagName('img'), retinaImages = [], i, image;
            for (i = 0; i < images.length; i += 1) {
                image = images[i];
                if (!!!image.getAttributeNode('data-no-retina')) {
                    newImage = new RetinaImage(image);
                    newImage.swap();
                    retinaImages.push();
                }
            }
        }
    }
);
