define('deleteModal',["jquery"],
    function($) {
        return function(deleteModal) {
            var modalForm = deleteModal.find('form');

            $('.delete-modal-link').click(function() {
                var action = modalForm.attr('action');
                if (action.indexOf('//', 8) !== -1) {
                    var start = action.indexOf('//', 8) + 1;
                    action = action.slice(0, start) + $(this).data('id') + action.slice(start);
                } else {
                    action = action + '/' + $(this).data('id')
                }
                modalForm.attr('action', action);

                if ($(this).data('name').length) {
                    $('.delete-name').html($(this).data('name'))
                }
            })
        }
    }
);
