define('components/header',['jquery'], function ($) {
    var mobileNavCollape = $('#navbar-collapse-1');
    var mobileMenuButton = $('.nav--expander');
    mobileNavCollape.on('show.bs.collapse', function () {
        $(mobileMenuButton).text('CLOSE')
    });
    mobileNavCollape.on('hide.bs.collapse', function () {
        $(mobileMenuButton).text('MENU')
    });
});
