define('pages/review-questions',[
    'jquery',
    'vue',
    'vueRouter',
    'text!components/review-list/email.vue',
    'text!components/review-list/appointment.vue',
    'text!components/review-list/reminder.vue',
    'text!components/review-list/message.vue',
    'text!components/review-list/final.vue',
    'analyticsSender',
    'vendor/jquery-ui',
    'validation'
], function ($, Vue, VueRouter, email, appointment, reminder, message, final, analyticsSender) {
    if ($('#review-your-list').length) {
        analyticsSender.loadPage('review-your-list');
        var successRoute = $('#success-route').val();
        if (typeof successRoute === 'undefined') {
            successRoute = '/success';
        }

        var showFinish = function () {
            $('.finish-button').show()
        };
        var closeModal= function () {
            $('.close').click();
        };
        $('#listModal').on('hidden.bs.modal', function(){
            if (!shouldRedirectToEmail()) {
                window.location = successRoute;
            } else {
                redirectToEmail();
            }

        });
        var modalOptions = {
            email: $('#worksheet-email').text(),
            confirmEmail: $('#worksheet-email').text(),
            reminder: 'false',
            month: '',
            day: '',
            year: '',
            worksheet: $('#worksheet-id').text(),
            message:  ''
        };
        var Email = Vue.component('email', {
            data: function () {
                return {
                    modalOptions: modalOptions,
                    submitText: 'submit'
                }
            },
            template: $(email).html(),
            methods: {
                submitEmail: function () {
                    //updateField('email', '/reminder');
                    // skipping to success!
                    updateField('email', successRoute);
                }
            }
        });

        var Message = Vue.component('message-component', {
            data: function () {
                return {
                    modalOptions: modalOptions
                }
            },
            template: $(message).html()
        });

        var Reminder = Vue.component('reminder', {
            data: function () {
                return {
                    modalOptions: modalOptions,
                    submitText: 'Yes, please remind me',
                    declineText: 'No, thanks'
                }
            },
            template: $(reminder).html(),
            methods: {
                acceptOption: function () {
                    modalOptions.reminder = 'true';
                    if (shouldRedirectToEmail()) {
                        redirectToEmail()
                    } else {
                        updateField('reminder', '/appointment');
                    }
                },
                declineOption: function () {
                    modalOptions.reminder= 'false';
                    updateField('reminder', successRoute);
                    closeModal()
                }
            }
        });

        var Appointment = Vue.component('appointment', {
            data: function () {
                return {
                    modalOptions: modalOptions,
                    submitText: 'SUBMIT',
                    declineText: 'I DON’T NEED TO BE REMINDED'
                }
            },
            template: $(appointment).html(),
            methods: {
                acceptOption: function () {
                    if (shouldRedirectToEmail()) {
                        redirectToEmail()
                    } else {
                        updateField('date', '/done');
                    }
                },
                declineOption: function () {
                    modalOptions.reminder = 'false';
                    closeModal();
                    updateField('reminder', successRoute);
                }
            }
        });

        var Final = Vue.component('final', {
            data: function () {
                return {
                    modalOptions: modalOptions,
                    submitText: 'OKAY'
                }
            },
            template: $(final).html(),
            methods: {
                success: function () {
                    if (shouldRedirectToEmail()) {
                        return redirectToEmail()
                    } else {
                        window.location = successRoute;
                    }
                }
            }
        });

        var App = Vue.extend({
            data: function () {
                return {
                    modalOptions: modalOptions
                }
            }
        });

        Vue.use(VueRouter);

        var router = new VueRouter({
            hashbang: false
        });

        router.map({

            '/': {
                component: Vue.component('email')
            },
            '/email': {
                component: Vue.component('email')
            },
            // '/reminder': {
            //     component: Vue.component('reminder')
            // },
            // '/appointment': {
            //     component: Vue.component('appointment')
            // },
            '/done': {
                component: Vue.component('final')
            }
        });
        router.start(App, '#vueModal');

        var contactUs = $('#contact-error-link');
        var updateField = function (field, route) {
            $.ajax({
                type: 'GET',
                url: '/review-list/send-appointment-' + field,
                contentType: 'application/json',
                dataType: 'json',
                data: modalOptions,
                success: function (response) {
                    modalOptions.message = '';
                    if (route == successRoute) {
                        window.location = route
                    } else  {
                        router.go(route);
                    }
                    contactUs.hide();
                },
                error: function (response) {
                    modalOptions.message = JSON.parse(response.responseText).message;
                }
            });
        };
        var shouldRedirectToEmail = function () {
            if (modalOptions.email == '') {
                return true;
            }
        };
        var redirectToEmail = function () {
            modalOptions.message = 'Please enter an email';

            return router.go('email');
        };
    }
});

