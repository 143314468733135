define('pages/concerns',['jquery'], function ($) {
    var nothingCheck = $('.question__item--concern input[type="checkbox"][name="no_concerns"]'),
        checkbox = $('.question__item--concern input[type="checkbox"]:not([name="no_concerns"])'),
        allCheckbox = $('.question__item--concern input[type="checkbox"]'),
        somethingInput = $('#concern-other'),
        submitButton = $('#select-concerns-form').find('button[type="submit"]'),
        alertNoCheck = $('#alert-no-concern'),
        allConcerns = $('.question__item--concern');

    // checks if there is input to submit
    var haveInput = function () {
        return allCheckbox.is(':checked') || somethingInput.val() !== '';
    };

    // disables the submit button
    var disableButton = function () {
        submitButton.attr('disabled', '');
    };

    // enables the submit button
    var enableButton = function () {
        submitButton.removeAttr("disabled");
    };

    // shows the alert that there is no input
    var showAlert = function () {
        alertNoCheck.fadeIn('fast');
    };

    // hides the alert that there is no input
    var hideAlert = function () {
        alertNoCheck.hide();
    };

    // checks if we have input and if we don't
    // then it disables the submit button.
    // but if we do, it enables the submit button
    var checkInputToggleButton = function () {
        if (!haveInput()) {
            disableButton();
        }
        else {
            enableButton();
        }
    };

    // run the check on load so that
    // if something is checked or filled in, we can move forward
    checkInputToggleButton();

    // when the "nothing specific" input is checked
    // remove all of the other checks
    nothingCheck.change(function () {
        if ((nothingCheck.is(":checked"))) {
            checkbox.prop("checked", false).change();
        }
        checkInputToggleButton();
    });

    // when any other checkbox taht is not nothing specific
    // is checkd - remove the nothing specific check
    checkbox.change(function () {
        if ($(this).is(':checked') && nothingCheck.is(":checked")) {
            nothingCheck.prop("checked", false).change();
        }
        hideAlert();
    });

    // when the custom concern text input changes,
    // uncheck all of the others
    somethingInput.change(function () {
        allCheckbox.prop("checked", false).change();
        checkInputToggleButton();
    });

    // when any checkbox changes, clear the custom input,
    // and check if we need to disable the button
    allCheckbox.change(function () {
        if ($(this).is(':checked')) {
            somethingInput.val('');
        }
        checkInputToggleButton();
    });

    // if somehow the submitbutton gets
    // clicked without any input, bail out
    submitButton.click(function (ev) {
        if (!haveInput()) {
            showAlert();
            ev.stopPropagation();
            return false;
        }
        return true;
    });
});

