define('SMSearch',["jquery", 'helpers/ajax-error'],
    function($, ajaxError) {
        return function (searchForm) {
            var input = searchForm.find('input');
            var perPage = searchForm.find('#perPage');
            var isArchived = searchForm.find('#isArchived');
            var url = searchForm.attr('action');

            input.keyup(function() {
                var q = $(this).val();
                var p = perPage.val();
                var a = isArchived.prop('checked');
                var data = {q: q, perPage: p, isArchived: a};
                searchNow(url, data);
            });
            perPage.change(function() {
                var q = input.val();
                var p = $(this).val();
                var a = isArchived.prop('checked');
                var data = {q: q, perPage: p, isArchived: a};
                searchNow(url, data)
            });
            isArchived.change(function() {
                var q = input.val();
                var p = perPage.val();
                var a = $(this).prop('checked');
                var data = {q: q, perPage: p, isArchived: a};
                searchNow(url, data)
            });


            $('th').each(function() {
                if ($(this).attr('data-sort')) {
                    $(this).data('sort-order', 'asc');
                    $(this).append(' <i class="fa fa-sort-desc"></i>')
                    $(this).click(function() {

                        var q = input.val();
                        var p = perPage.val();
                        var a = isArchived.prop('checked');
                        var s = $(this).data('sort');
                        var so = $(this).data('sort-order');
                        var data = {q: q, perPage: p, sort: s, sortOrder: so, isArchived: a};
                        searchNow(url, data)
                        if ($(this).data('sort-order') == 'asc') {
                            $(this).data('sort-order', 'desc').find('.fa').remove();
                            $(this).append('<i class="fa fa-sort-asc"></i>')
                        } else {
                            $(this).data('sort-order', 'asc').find('.fa').remove();
                            $(this).append('<i class="fa fa-sort-desc"></i>')
                        }

                    })
                }
            })

            function searchNow(url, data) {
                $.ajax({
                    url: url,
                    type: 'GET',
                    data: data,
                    success: function (data) {
                        var returnedHtml = $(data);

                        $('#results').empty().html(returnedHtml.find('#results').html());
                        $('.pagination-wrapper').html(returnedHtml.find('.pagination-wrapper').html());
                    },
                    error: function () {
                        ajaxError('Error Searching');
                    }
                });
            }
        }
    }
);
