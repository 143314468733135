requirejs.config({
    paths: {
        pages:           'pages',
        admin:           'admin',
        components:      'components',
        vendor:          'vendor',
        confirmModal:    'helpers/confirm-modal',
        autoSearch:      'helpers/auto-search',
        deleteModal:     'helpers/delete-modal',
        ratingWidget:    'helpers/rating-widget',
        jsonParser:      'helpers/json-parser',
        analyticsSender: 'helpers/analytics-sender',
        jquery:          '../vendor/jquery/dist/jquery.min',
        popper:          '../vendor/popper.js/dist/umd/popper.min',
        bootstrap:       '../vendor/bootstrap/dist/js/bootstrap.bundle.min',
        EventEmitter:    'vendor/EventEmitter', // required for GA
        candc:           '../vendor/candc-theme-assets/dist/candc', // for our theme dependency
        GA:              'vendor/GoogleAnalytics',
        dropzone:        '../vendor/dropzone/dist/dropzone-amd-module',
        addThis:         '//s7.addthis.com/js/300/addthis_widget',
        validation:      '../vendor/jquery-validation/dist/jquery.validate.min',
        vue:             '../vendor/vue/dist/vue.min',
        vueRouter:       '../vendor/vue-router/dist/vue-router.min',
        text:            '../vendor/text/text',
        vimeo:           '//player.vimeo.com/api/player',
        featherlight:    'vendor/featherlight',
        scrollDepth:     'vendor/jquery.scrolldepth.min',
        gaConfig:        'ga_config.json',
        SMSearch:        'vendor/laravel-search',
        lodash:          '../vendor/'
    },
    shim:  {
        "bootstrap":          {"deps": ['jquery', 'popper']},
        'bootstrap/collapse': {deps: ['jquery'], exports: '$.fn.collapse'},
        'bootstrap/dropdown': {deps: ['jquery'], exports: '$.fn.dropdown'},
        "candc":              {"deps": ["jquery"]}
    }
});

requirejs([
        'jquery',
        'confirmModal',
        'deleteModal',
        'GA',
        'analyticsSender',
        'SMSearch',
        'bootstrap',
        '../vendor/candc-theme-assets/dist/js/candc',
        'pages/_load',
        'admin/_load',
        'components/_load',
        'utils/_load',
        'ratingWidget',
        'scrollDepth'
    ],
    function ($, confirmModal, deleteModal, GA, analyticsSender, smSearch) {
        $(document).ready(function () {
            requirejs(['addThis']);
            var confirm_modal = $('#confirm-modal');
            if (confirm_modal.length) {
                confirmModal(confirm_modal)
            }

            var delete_modal = $('.delete-modal');
            if (delete_modal.length) {
                deleteModal(delete_modal)
            }

            $('a[href*="#"]:not([href="#"])').click(function () {
                if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    if (target.length) {
                        $('html, body').animate({
                            scrollTop: target.offset().top - 10
                        }, 1000);

                        return false;
                    }
                }
            });

            $('.above-content-banner-text').click( function (e) {
                $('#above-content-banner').slideUp(1000);
            });

            if ($('#search-form').length) {
                smSearch($('#search-form'))
            }

            GA.ready(function (ga) {
                analyticsSender.init();
                $.scrollDepth({
                    minHeight:      80,
                    elements:       ['#about', '.hero'],
                    percentage:     false,
                    userTiming:     true,
                    pixelDepth:     false,
                    nonInteraction: false,
                    gtmOverride:    false
                });
            });
        });
    }
);




define("main.js", function(){});

