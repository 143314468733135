define('admin/assets',['jquery', 'dropzone', 'helpers/ajax-error'],
    function ($, Dropzone, ajaxError) {
        var directory = $('#directory-name').html();
        if ($('#assets').length && directory) {
            Dropzone.autoDiscover = false;
            $("#createAsset").dropzone(
                {
                    paramName: "asset", // The name that will be used to transfer the file
                    headers: {"Directory": directory},
                    maxFilesize: 5, // MB
                    init: function () {
                        this.on("success", function () {
                            location.reload();
                        });
                        this.on("error", function (file, errorMessage) {
                            ajaxError(errorMessage, file.name);
                            this.removeFile(file);
                        });
                    }
                }
            );

            $('.delete-asset').click(function () {
                var id = $(this).data('id');
                var parent = $(this).parents('.asset');
                var token = $("input[name='_token']").val();
                var fileName = parent.find('h4').html();
                $.ajax({
                    url: '/admin/assets/' + id,
                    type: 'POST',
                    data: {_method: 'delete', _token: token},
                    success: function () {
                        parent.fadeOut();
                    },
                    error: function () {
                        ajaxError('Error Removing File', fileName);
                    }
                });
            })
        }
    }
);
