define('admin/assets-image-widget',['jquery'], function ($) {
    if ($('.asset-image-async-upload-form').length) {
        console.log("hello");

        /**
         * Async asset image uploading
         */
        $(document).ready(function() {

            /**
             * Updates Image Preview on 'select' change or page refresh.
             */
            function updateFeaturedImagePreview() {
                $('select.asset-image-select-dropdown').each(function() {
                    var img_src = $(this).find('option:selected').data('img-src');

                    var preview_id = $(this).data('preview-id');
                    $(preview_id + ' img').attr('src', img_src);

                });
            }

            // Update preview on page load.
            updateFeaturedImagePreview();

            // Update preview on select change.
            $('select.asset-image-select-dropdown').change(function() {
                updateFeaturedImagePreview();
            });


            /**
             * Uploads file to server
             */
            function uploadFile(url, formData, modal_id, field_name) {
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: formData,
                    cache: false,
                    dataType: 'json',
                    processData: false, // Don't process the files
                    contentType: false,
                    success: function(data, textStatus, jqXHR) {
                        if (data.status == 'ok') {

                            refreshAssetSelectField(modal_id, field_name, data.id);

                            $(modal_id).modal('hide');

                        } else {
                            console.log('ERRORS: ' + data.error);
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        console.log('ERRORS: ' + textStatus);
                    }
                });
            }


            /**
             * Refreshes the 'select' dropdown by getting the list of matching assets from an ajax call
             */
            function refreshAssetSelectField(modal_id, field_name, new_id) {
                var asset_directory = $(modal_id).find('input[name=directory]').val();

                if (asset_directory) {
                    url = '/admin/assets/asset-images?directory=' + asset_directory;
                    $.ajax({
                        url: url,
                        type: 'GET',
                        cache: false,
                        dataType: 'json',
                        success: function(data) {
                            console.log(data);
                            if (data.status == 'ok') {

                                var asset_select_field = $('select[name=' + field_name + ']');

                                asset_select_field.find('option:not(.default-placeholder)').remove();

                                var assets = data.assets;

                                if (assets) {
                                    for (var i = 0; i < assets.length; i++) {
                                        item = assets[i];
                                        var option_tag = $('<option value="' + item.id + '" data-img-src="/' + item.full_dist_path + '">' + item.name + '</option>');

                                        asset_select_field.append(option_tag);
                                    }

                                    if (new_id) {
                                        asset_select_field.val(new_id);
                                        updateFeaturedImagePreview();
                                    }
                                }

                            } else {
                                console.log('ERRORS: ' + data.error);
                            }
                        },
                        error: function(jqXHR, textStatus, errorThrown) {
                            console.log('ERRORS: ' + textStatus);
                            console.log(jqXHR);
                        }
                    });
                }
            }


            /**
             * Triggers async image uploading:
             * 1. upload image via asset-manager
             * 2. refresh asset 'select' field to include newest item
             * 3. select new 'option' from drop-down
             */
            $('.asset-image-async-upload-form .upload-button').click(function() {
                console.log("got click");
                var modal_id = $(this).data('parent-modal');
                var formData = new FormData();
                var file_field_id = $(this).data('file-field');
                var field_name = $(this).data('field-name');
                var fileField = document.getElementById(file_field_id);

                if (fileField && fileField.files && fileField.files.length) {
                    var imageFile = fileField.files[0];
                    formData.append('asset', imageFile);
                    formData.append('directory', $(this).data('asset-directory'));
                    uploadFile('/admin/assets/upload-asset-image', formData, modal_id, field_name);
                }
                else {
                    console.log("no files", fileField);
                }
            });
        });

    }

});

