define('confirmModal',["jquery"],
    function($) {
        return function(confirmModal) {
        //pass in a jquery element

            //find the form on the passed in element
            var modalForm = confirmModal.find('form');

            //confirm-modal-link id clicked should trigger the finding of specific elements on the passed in jquery elemement
            $('.confirm-modal-link').click(function() {
                if ($(this).data('title').length) {
                    confirmModal.find('h2').html($(this).data('title'));
                }

                if ($(this).data('subtitle').length) {
                    confirmModal.find('.subtitle').html($(this).data('subtitle'));
                }

                var field = $(this).data('field');
                var secondary = $(this).data('secondary');

                if (field.length) {
                    var newVal = $('input[name=' + field + ']').val();
                    modalForm.find("input[name='field']").val(newVal).attr('name', $(this).data('field'));
                }
                if (secondary.length) {
                    var newVal2 = $('input[name=' + secondary + ']').val();
                    modalForm.find("input[name='secondary']").val(newVal2).attr('name', $(this).data('secondary'));
                }

            })
        }
    }
);
