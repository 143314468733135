define('utils/video-play',["jquery", 'GA', 'vimeo'], function ($, GA, Player) {
    $(document).ready(function () {


        if ($('#resource-video').length) {
            var playButton = $('.play-btn');
            var iframe = document.querySelector('iframe');
            var player = new Player(iframe);
            var closeButton = $('.video__close-icon');


            GA.ready(function (ga) {
                player.on('play', function () {
                    ga('send', 'event', 'Video', 'Started video', 'Resource Video');
                });
                player.on('pause', function () {
                    ga('send', 'event', 'Video', 'Paused video', 'Resource Video');
                });

                player.on('ended', function () {
                    ga('send', 'event', 'Video', 'Completed video', 'Resource Video');
                });
            });
            $('#videoModal').on('hidden.bs.modal', function(){
                player.pause();
            });

            if (playButton.length) {
                playButton.bind('click', function () {
                    player.play();
                });
            }
            if (closeButton.length) {
                closeButton.bind('click', function () {
                    player.unload();
                });
            }
        }
    });

});

