define('admin/_load',['jquery', 'autoSearch', 'admin/assets', 'admin/assets-image-widget'],
    function ($, autoSearch) {
        if ($('#admin-container').length) {
            var searchForm = $('#search-form');
            if (searchForm.length) {
                console.log('found');
                autoSearch(searchForm)
            }
        }
    }
);

