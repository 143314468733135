define('helpers/ajax-error',["jquery"],
    function($) {
        return function(response, errorName) {
            var errorDiv = $('#above-content-alert'), errorMsg;
            if ($.isPlainObject(response)) {
                for (var prop in response) {
                    // skip loop if the property is from prototype
                    if(!response.hasOwnProperty(prop)) continue;

                    if(response[prop].length === 1) {
                        errorMsg = response[prop][0];
                        if (errorMsg) {
                            errorDiv.append('<p><strong>' + errorName + '</strong>: ' + errorMsg + '</p>')
                        }
                    } else if(response[prop].length > 1) {
                        errorMsg = response[prop];
                        errorDiv.append('<p><strong>' + prop.charAt(0).toUpperCase() + prop.slice(1) + '</strong>: ' + errorMsg + '</p>')
                    }
                }
            }
            if ($.isArray(response)) {
                if (response.length === 1) {
                    errorMsg = response[0];
                    errorDiv.append('<p><strong>' + errorName + '</strong>: ' + errorMsg + '</p>')
                } else {
                    response.forEach(function(errorMsg) {
                        errorDiv.append('<p><strong>' + errorName + '</strong>: ' + errorMsg + '</p>')
                    });
                }
            }

            errorDiv.removeClass('hidden').removeClass('alert-success').addClass('alert-danger');
        }
    }
);
