define('ratingWidget',["jquery"], function ($) {
    $(document).ready(function () {

        $('.rating-widget').each(function () {
            var parent_container = $(this);
            var $surveyNoLink = parent_container.find('button.survey-no'),
                $surveyDiv = parent_container.find('.survey-wrap');

            $surveyNoLink.click(function (ev) {
                ev.preventDefault();
                $surveyDiv.fadeOut();
                return false;
            });

            $(this).find('.rating-start .star-button').click(function (e) {
                var rating_value = $(this).data('val');
                var api_url = '/save-rating?rating=' + rating_value;

                ga('send', {
                    hitType: 'event',
                    eventCategory: 'Rating',
                    eventAction: 'rate',
                    eventLabel: 'Dr Decoder Rating',
                    eventValue: +rating_value
                });

                $.getJSON(api_url, function (data) {
                    if (data.status == 'ok') {
                        var rating_text = 'You gave ' + rating_value + ' out of 5';
                        var $done_container = parent_container.find('.rating-done');
                        $done_container.hide().removeClass('hidden');
                        $done_container.find('.score').text(rating_text);

                        parent_container.find('.rating-start').fadeOut(function () {
                            $done_container.fadeIn();
                        });
                    }
                });
            });

            $(this).find('.update-rating-link').click(function (e) {
                parent_container.find('.rating-done').fadeOut(function () {
                    addStarHoverEffect();
                    parent_container.find('.rating-start').fadeIn();
                    $('.rating-stars-wrap').removeClass('hidden');
                });
                e.preventDefault();
            });

            $(this).find('.survey-section .hide-survey').click(function (e) {
                parent_container.find('.survey-section').addClass('hide-forever').fadeOut();
                e.preventDefault();
            });

        });

        function addStarHoverEffect() {
            var enter = 'mouseenter.star-effect',
                leave = 'mouseleave.star-effect',
                click = 'click.star-effect',
                $star = $('.rating-start .star-button'),
                removeStars = function() {
                    $star.removeClass('fa-star');
                    $star.addClass('fa-star-o');
                },
                addStars = function () {
                    $(this).removeClass('fa-star-o');
                    $(this).addClass('fa-star');
                    $(this).prevAll().removeClass('fa-star-o');
                    $(this).prevAll().addClass('fa-star');
                };

            // clear any stars first
            removeStars();

            // unbind first in case events are bound
            $star.unbind(enter);
            $star.unbind(click);
            $star.unbind(leave);

            // bind all the events
            $star.bind(enter, addStars);
            $star.bind(click, function(ev) {
                ev.stopPropagation();
                $star.unbind(leave);
                return false;
            });
            $star.bind(leave, removeStars);
        }

        addStarHoverEffect();

    });

});

