define('pages/_load',[
  'pages/post-index',
  'pages/review-questions',
  'pages/select-questions',
  'pages/concerns',
  'pages/success',
  'pages/select-interactions'
  ], function () {

});

