define('analyticsSender',['jquery', 'GA', 'text!gaConfig'], function ($, GA, gaConfig) {
    return {
        gaConfigJson: JSON.parse(gaConfig),
        ready: false,
        init: function () {
            var self = this;
            if (!self.ready) {
                GA.ready(function (ga) {
                    var config = self.gaConfigJson;
                    var gaProperty = self.getGaPropertyID(location.hostname, config.gaPropertyNames);
                    ga('create', gaProperty);
                    self.setGlobalDimensions(config.gaDimensions);
                    ga('send', 'pageview');
                    //add any global click handlers in ga_config.json
                    self.setGlobalEvents(config.globalEvents);
                    self.loadComponents();
                    self.markReady();
                })
            }
        },
        getGaPropertyID: function(currentDomain, domainsObject) {
            var property = domainsObject.default;
            $.each(domainsObject, function (name, propertyId) {
                if (currentDomain.match(name)) {
                    property = propertyId;
                }
            });

            return property;
        },
        setGlobalDimensions: function (globalDimensions) {
            var self = this;
            $.each(globalDimensions, function (index, globalDimension) {
                var globalDimensionValue = self.getValueFromConfig(globalDimension.value);
                ga('set', globalDimension.name, globalDimensionValue)
            });
        },
        setGlobalEvents: function (globalEvents) {
            var self = this;
            $.each(globalEvents, function (index, globalEvent) {
                self.attachListener(globalEvent);
            });
        },
        loadPage: function (pageName) {
            this.init();
            if (pageName !== undefined) {
                var self = this;
                $.each(this.gaConfigJson.pageNames[pageName], function (index, gaEventAttributes) {
                    self.prepare(gaEventAttributes);
                });
            }
        },
        loadComponents: function () {
            var self = this;
            $.each(this.gaConfigJson.components, function (index, gaEventAttributes) {
                if ($(index).length) {
                    self.prepare(gaEventAttributes, index);
                }
            });
        },
        attachListener: function (gaEventAttributes) {
            var pageElement = $(gaEventAttributes.selector);
            var self = this;
            return pageElement.on(gaEventAttributes.eventType, function () {
                self.trigger(gaEventAttributes);
            });
        },
        send: function (category, action, label, value) {
            if (window.ga) {
                if (category === undefined) {
                    category = window.location.pathname;
                }
                if (label === undefined) {
                    label = null;
                }
                if (value === undefined) {
                    value = null;
                }
                ga('send', {
                    hitType:       'event',
                    eventCategory: category,
                    eventAction:   action,
                    eventLabel:    label,
                    eventValue:    value
                })
            }
        },
        getValueFromConfig: function (valueField) {
            if  (valueField === undefined) {
                value = null;
            } else if ($.isPlainObject(valueField)) {
                value = $(valueField.element).attr(valueField.attribute);
            } else {
                value = valueField;
            }

            return value;
        },
        prepare: function (attributes) {
            if (attributes.eventType == 'automatic') {
                this.trigger(attributes);
            } else {
                this.attachListener(attributes);
            }
        },
        trigger: function (attributes) {
            var value = this.getValueFromConfig(attributes.value);
            var label = this.getValueFromConfig(attributes.label);
            this.send(attributes.category, attributes.gaAction, label, value);
        },
        markReady: function () {
            this.ready = true;
        }
    }
});

