/**
 * For the select interaction type page
 */
define('pages/select-interactions',['jquery'], function () {
    if ($('#select-interaction-types').length) {
        var $form = $('#select-interaction-types form'),
                $inputs = $form.find('input[name="type"]'),
                $submitButton = $form.find('button[type="submit"]');

        // our methods
        // disables the submit button
        var disableButton = function () {
                $submitButton.attr('disabled', '');
                $submitButton.attr('title', 'Must select from above to continue.');
            },
            // enables the submit button
            enableButton = function () {
                $submitButton.removeAttr("disabled");
                $submitButton.removeAttr("title");
            },
            // check if we have inputs
            haveInput = function () {
                return $inputs.is(':checked');
            },
            checkInputsToggleButton = function () {
                if (!haveInput()) {
                    disableButton();
                }
                else {
                    enableButton();
                }
            };

        checkInputsToggleButton();

        $inputs.change(checkInputsToggleButton);
    }
});

